import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '404',
    meta: {
      title: '经纪人管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/404/Index.vue'),
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/login/Index.vue'),
  },

  {
    path: '/index',
    name: 'index',
    meta: {
      title: '经纪人管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/index/Index.vue'),
  },

  {
    path: '/bankList',
    name: 'bankList',
    meta: {
      title: '银行卡管理',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank/Index.vue'),
  },

  {
    path: '/wechatOfficialAccountData',
    name: 'wechatOfficialAccountData',
    meta: {
      title: '公众号数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/wechat_official_account_data/Index.vue'),
  },

  {
    path: '/prepareData',
    name: 'prepareData',
    meta: {
      title: '进件数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/prepare_data/Index.vue'),
  },

  {
    path: '/transfer',
    name: 'transferData',
    meta: {
      title: '转账记录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/transfer/Index.vue'),
  },

  {
    path: '/transferdo',
    name: 'transfer',
    meta: {
      title: '转账',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/transfer/transfer.vue'),
  },
  {
    path: '/bankdo',
    name: 'bankdo',
    meta: {
      title: '添加银行卡',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank/bank.vue'),
  },


  {
    path: '/statistics/bank',
    name: 'statisticsBank',
    meta: {
      title: '银行卡数据统计',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/statistics/bank.vue'),
  },
  {
    path: '/statistics/transfer',
    name: 'statisticsTransfer',
    meta: {
      title: '转账数据统计',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/statistics/transfer.vue'),
  },
  {
    path: '/statistics/product',
    name: 'statisticsProduct',
    meta: {
      title: '产品数据统计',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/statistics/product.vue'),
  },

  //
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && (document.title = to.meta.title);
  if (to.meta && to.meta.anonymous) {
    return next()
  }
  next()
});

export default router;
